import styled from 'styled-components';
import { maxQuery } from '../../utilities';
import { StyledButton } from '../../ui/Button/styles';
import { SubcriptionBg } from '../../assets/images';
import { CheckMark } from '../../assets/svgs';

export const Container = styled.section`
	padding: 3rem 8.5rem 6rem;
	padding: 3rem clamp(2rem, -1.75rem + 12vw, 8.75rem) 6rem;
	text-align: center;

	.controls {
		display: flex;
		width: fit-content;
		margin: 3.63rem auto 4rem;
		margin: 3.63rem auto clamp(2rem, 0rem + 6.4vw, 4rem);
		border-radius: 12.5rem;
		background: #f2f4fd;
		flex-wrap: wrap;
		justify-content: center;

		${maxQuery(360)} {
			flex-direction: column;
			justify-content: center;
			background-color: transparent;
		}

		${StyledButton} {
			border-radius: 12.5rem;
			font-family: 'Nanum Pen Script', cursive;
			font-size: 1.35rem;
			font-size: clamp(1rem, 0.65rem + 1.4vw, 1.35rem);
			font-weight: 400;
			line-height: 1.3;
			justify-content: center;
			padding: 1rem 2.5rem;
			padding: clamp(0.8rem, 0.6rem + 0.64vw, 1rem) clamp(1rem, 0.1667rem + 2.6667vw, 2.5rem);

			:not(.active) {
				color: #4060ea;
				border-color: transparent;
				background-color: #f2f4fd;
			}
		}
	}

	h3 {
		color: #070d19;
		font-size: 3.125rem;
		font-size: clamp(2rem, 1.4016rem + 1.9149vw, 3.125rem);
		line-height: 130%;
	}
	> p {
		margin: auto;
	}

	.hidden {
		background: url(${SubcriptionBg});
	}
	.institution-con svg {
		max-width: 100%;
		height: auto;
	}

	.plans {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem 2rem;
		margin: 3rem 0 2.5rem;
		margin: clamp(1.5rem, 0.6667rem + 2.6667vw, 3rem) 0 clamp(1.5rem, 0.9444rem + 1.7778vw, 2.5rem);

		${maxQuery('lg')} {
			display: none;
		}

		&.intern {
			display: flex;
			justify-content: center;

			${maxQuery('lg')} {
				display: none;
			}

			> div {
				width: 22.54969rem;
			}
		}

		> div,
		> a {
			border-radius: 0.3125rem;
			background: #f1f4fe;
			padding: 0 2rem 2rem;
			padding: 0 clamp(1rem, 0.4444rem + 1.7778vw, 2rem) clamp(1rem, 0.4444rem + 1.7778vw, 2rem);

			&.active {
				border: 2px solid #4060ea;
			}

			:hover {
				background: url(${SubcriptionBg});

				&.active {
					outline: 2px solid #4060ea;
				}

				.duration,
				.price,
				.video,
				.condition,
				ul {
					color: #fff;
				}
				.promo {
					color: #4060ea;
					span {
						background-color: #f2c94c;

						svg path {
							fill: #4060ea;
						}
					}

					.bg path {
						fill: #f2c94c;
					}
				}

				${StyledButton} {
					color: #4060ea;
					background-color: #fff;
					border: 1px solid #fff;
				}
			}

			span {
				display: block;
			}
			.promo {
				width: max-content;
				margin: auto;
				color: #fff;
				font-family: 'Rethink Sans', sans-serif;
				font-size: 0.902rem;
				font-weight: 700;
				letter-spacing: 0.09769rem;
				position: relative;
				margin-bottom: 3rem;

				span {
					display: flex;
					align-items: center;
					gap: 0.23rem;
					background-color: #4060ea;
					padding: 0.4rem 1.2rem 0;
					position: relative;
					z-index: 2;
				}

				.bg {
					position: absolute;
					z-index: 1;
					width: calc(100% + 2px);
					height: auto;
					top: 0;
					left: 0;
				}
			}
			.duration {
				color: #4f5362;
				text-decoration: underline;
				text-transform: uppercase;
				font-size: 0.88425rem;
				font-weight: 700;
				line-height: 150%;
				letter-spacing: 0.05894rem;
				margin-bottom: 1.47rem;
			}
			.price.old {
				color: #ea2d2e;
				font-size: 2.22rem;
				font-size: clamp(1rem, 0.3333rem + 2.1333vw, 2.2rem);
				position: relative;
				width: fit-content;
				margin: 0 auto 3.23rem;

				::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					border-top: 2px solid #ea2d2e;
				}
			}
			.price {
				color: #4060ea;
				font-size: 3.6rem;
				font-size: clamp(1.5rem, 0.3333rem + 3.7333vw, 3.6rem);
				font-weight: 500;
				/* font-size: clamp(1rem, 0.2222rem + 2.4889vw, 2.4rem); */
				line-height: 1.1;
				font-weight: 500;
				margin: 0 auto 0.5rem;

				&.no-promo {
					margin: 0 auto 2rem;
				}
			}
			.video {
				color: #4060ea;
				font-size: 1rem;
				font-weight: 700;
				line-height: 150%;
				text-transform: uppercase;
				text-decoration: underline;
			}
			ul {
				color: #4f5362;
				font-size: 0.925rem;
				line-height: 150%;
				letter-spacing: 0.02456rem;
				width: fit-content;
				margin: 1.79rem auto 4.47rem;

				li {
					position: relative;
					width: max-content;
					margin: 0 auto 0.5rem;

					::before {
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: -1.3rem;
						background-image: url(${CheckMark});
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						width: 0.8rem;
						height: 0.8rem;
					}
				}
			}
			.get-started {
				display: inline-block;
				width: 100%;
				margin-top: 2rem;
			}
			${StyledButton} {
				width: 100%;
				justify-content: center;
			}
		}
	}
	.plans.mobile {
		display: none;
		grid-template-columns: 1fr;
		gap: 1rem 2rem;
		margin: 3rem 0 2.5rem;
		margin: clamp(1.5rem, 0.6667rem + 2.6667vw, 3rem) 0 clamp(1.5rem, 0.9444rem + 1.7778vw, 2.5rem);

		${maxQuery('lg')} {
			display: grid;
		}

		> a {
			display: grid;
			grid-template-columns: 1fr auto;
			padding: 3.5rem 2rem 2rem;
			padding: clamp(2rem, 0.5rem + 4.8vw, 3.5rem) clamp(1.25rem, 0.5rem + 2.4vw, 2rem) clamp(1.25rem, 0.5rem + 2.4vw, 2rem);
			position: relative;

			> div:first-child {
				text-align: left;
			}
			> div:last-child {
				display: grid;
				margin-top: 1.3rem;
				align-items: center;
				text-align: right;
			}

			${StyledButton} {
				margin: 0.63rem 0 0 auto;
				width: max-content;
				padding: 0.7rem 1.2rem;
			}
		}
		.promo {
			position: absolute;
			top: 0;
			left: unset;
			right: 2rem;
			right: clamp(1rem, 0rem + 3.2vw, 2rem);
			font-size: 0.9rem;
			font-size: clamp(0.5rem, 0.1rem + 1.28vw, 0.9rem);

			span {
				background-color: transparent !important;
				padding: 0.4rem 0.75rem 0;
				padding: clamp(0.2rem, 0rem + 0.64vw, 0.4rem) clamp(0.5rem, 0.25rem + 0.8vw, 0.75rem) 0;
			}
			span svg {
				height: auto;
				width: clamp(0.875rem, -0.125rem + 3.2vw, 1.875rem);
			}
		}
		.duration {
			color: #090909;
			font-size: 1.25rem;
			font-size: clamp(1rem, 0.75rem + 0.8vw, 1.25rem);
			font-weight: 800;
			margin-bottom: 1rem;
		}
		ul {
			color: #090909;
			margin: 0.5rem 0 0 1.4rem;
			font-size: 1rem;
			font-size: clamp(0.625rem, 0.25rem + 1.2vw, 1rem);
		}
		.price {
			font-weight: 600;
			font-size: 2.3rem;
			font-weight: 500;
			font-size: clamp(1.25rem, 0.2rem + 3.36vw, 2.3rem);
			margin: 0 0 0 auto !important;

			&.old {
				margin: 0 0 0.5rem 0;
			}
		}
		.refund {
			margin: 0 0 0.5rem auto;
			padding: 0;
			background-color: transparent;
			position: relative;

			::before {
				position: absolute;
				content: '';
				background: #52db94;
				width: 0.75rem;
				height: 0.75rem;
				top: 50%;
				transform: translateY(-50%);
				left: -1rem;
				border-radius: 50%;
			}
		}
		.condition {
			justify-content: flex-start;
			margin-top: 0.5rem;
		}
		.video {
			padding: 0.5rem 0 0.5rem 1rem;
			font-size: 1rem;
		}
	}
`;
